/**
 * Created by Artur on 15. 02. 2023..
 */
export default (uiText) => {
    return (
        `<div class="op-navigators">` +
            `<button class="op-button op-chat-button" type="button" style="padding-right: 10px;">` +
                // `<i class="op-con op-arrow-left"></i>` +
                // `<img src="http://primedepartamentos.com/images/icons/chat-icon-white.png"` +
                '<svg width="2em" height="2em" viewBox="0 0 1024 1024" xmlns="http://www.w3.org/2000/svg"><path fill="#ffffff" d="M273.536 736H800a64 64 0 0 0 64-64V256a64 64 0 0 0-64-64H224a64 64 0 0 0-64 64v570.88L273.536 736zM296 800 147.968 918.4A32 32 0 0 1 96 893.44V256a128 128 0 0 1 128-128h576a128 128 0 0 1 128 128v416a128 128 0 0 1-128 128H296z"/><path fill="#000000" d="M512 499.2a51.2 51.2 0 1 1 0-102.4 51.2 51.2 0 0 1 0 102.4zm192 0a51.2 51.2 0 1 1 0-102.4 51.2 51.2 0 0 1 0 102.4zm-384 0a51.2 51.2 0 1 1 0-102.4 51.2 51.2 0 0 1 0 102.4z"/></svg>' +
            `</button>` +
        `</div>`
    );
};
