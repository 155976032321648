/**
 * Created by Artur on 29. 11. 2022..
 */
export default (uiText) => {
    return (
        `<div class="op-navigators">` +
            `<button class="op-button op-previous-cam-button" type="button" style="padding-right: 10px;">` +
                `<i class="op-con op-arrow-left"></i>` +
            `</button>` +
            `<button class="op-button op-next-cam-button" type="button" style="padding-left: 10px;">` +
                `<i class="op-con op-arrow-right"></i>` +
            `</button>` +
        `</div>`
    );
};
