/**
 * Created by Artur on 15. 02. 2023..
 */
import OvenTemplate from "view/engine/OvenTemplate";

const ChatButton = function ($container, api) {

    const onRendered = function($current, template){
    };

    const onDestroyed = function(template){
    };

    const events = {
        "click .op-chat-button" : function(event, $current, template){
        },

        "click .op-chat-button": function(event, $current, template) {
        },
    };

    return OvenTemplate($container, "ChatButton", api.getConfig(), null, events, onRendered, onDestroyed );
};

export default ChatButton;
