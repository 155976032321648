/**
 * Created by Artur on 29. 11. 2022..
 */
import OvenTemplate from "view/engine/OvenTemplate";

const CamButton = function ($container, api) {
    const onRendered = function ($current, template) {};

    const onDestroyed = function (template) {};

    const events = {
        "click .op-previous-cam-button": function (event, $current, template) {
            const sources = api.getSources();
            if (sources.length > 1) {
                const currentSourceIndex = api.getCurrentSource();
                let previousSourceIndex = currentSourceIndex - 1;
                if (previousSourceIndex < 0) {
                    previousSourceIndex = sources.length - 1;
                }
                api.setCurrentSource(previousSourceIndex);
            }
        },

        "click .op-next-cam-button": function (event, $current, template) {
            const sources = api.getSources();
            if (sources.length > 1) {
                const currentSourceIndex = api.getCurrentSource();
                let nextSourceIndex = currentSourceIndex + 1;
                if (nextSourceIndex > sources.length - 1) {
                    nextSourceIndex = 0;
                }
                api.setCurrentSource(nextSourceIndex);
            }
        }
    };

    return OvenTemplate($container, "CamButton", api.getConfig(), null, events, onRendered, onDestroyed);
};

export default CamButton;
